import { createTheme } from "@material-ui/core";
import GilRoyEBWoff from './fonts/Gilroy-ExtraBold.woff';
import GilRoyLWoff from './fonts/Gilroy-Light.woff';
import SpaceGroteskBWoff from './fonts/SpaceGrotesk-Bold.woff2';
import SpaceGroteskMWoff from './fonts/SpaceGrotesk-Medium.woff2';
import SpaceGroteskRWoff from './fonts/SpaceGrotesk-Regular.woff2';
import SpaceGroteskLWoff from './fonts/SpaceGrotesk-Light.woff2';

const gilroyEB = {
  fontFamily: 'Gilroy',
  fontStyle: 'extra-bold',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('Gilroy'),
    local('Gilroy-ExtraBold'),
    url(${GilRoyEBWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const gilroyL = {
  fontFamily: 'Gilroy',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('Gilroy'),
    local('Gilroy-Light'),
    url(${GilRoyLWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const spaceGroteskB = {
  fontFamily: 'SpaceGrotesk',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('SpaceGrotesk'),
    local('SpaceGrotesk-Bold'),
    url(${SpaceGroteskBWoff}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const spaceGroteskM = {
  fontFamily: 'SpaceGrotesk',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('SpaceGrotesk'),
    local('SpaceGrotesk-Medium'),
    url(${SpaceGroteskMWoff}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const spaceGroteskR = {
  fontFamily: 'SpaceGrotesk',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('SpaceGrotesk'),
    local('SpaceGrotesk-Regular'),
    url(${SpaceGroteskRWoff}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const spaceGroteskL = {
  fontFamily: 'SpaceGrotesk',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('SpaceGrotesk'),
    local('SpaceGrotesk-Light'),
    url(${SpaceGroteskLWoff}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main:'#000000',
      contrastText: '#ffffff',
      contrastColor: '#ffffff',
    },
    secondary: {
      main:'#393FCD',
      contrastText: '#ffffff'
    },
    blue: {
      main:'#393FCD',
      contrastText: '#ffffff'
    },
    green: {
      main: '#018A55',
      contrastText: '#ffffff'
    },
    yellow: {
      main:'#FFE400',
    },
    pink: {
      main:'#FABFB7',
    },
    orange: {
      main:'#EF7D4C',
    },
  },
  typography: {
    fontFamily: ['Gilroy', 'SpaceGrotesk', 'sans-serif'].join(','),
    fontFamily: ['SpaceGrotesk', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'SpaceGrotesk, sans-serif',
      fontWeight: 200,
      fontSize: '4.5rem'
    },
    h2: {
      fontFamily: 'Gilroy, sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Gilroy, sans-serif',
      fontWeight: 700,
      fontSize: '3rem',
    },
    h4: {
      fontFamily: 'Gilroy, sans-serif',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'SpaceGrotesk, sans-serif',
      fontWeight: 600,
      fontSize: '1.35rem',
      lineHeight: 1.5,
    },
    h6: {
      fontFamily: 'SpaceGrotesk, sans-serif',
      fontWeight: 700,
      fontSize: '1.15rem',
    },
    subtitle1: {
      fontWeight: 500,
      paddingBottom: '1.15rem',
      lineHeight: 1.5
    },
    body1: {
      fontFamily: 'SpaceGrotesk, sans-serif',
      fontWeight: 500,
    },
    body2: {
      lineHeight: 1.5,
      fontSize: '1rem',
      fontWeight: 700,
    },
    p: {
      fontFamily: 'SpaceGrotesk, sans-serif',
      fontSize: '1rem',
    },
  },
  overrides: {
    MuiButton: {
      root:{
        textTransform: "none",
        fontSize: "1rem",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "#FFE400",
        },
        "& .MuiTouchRipple-root span":{
          backgroundColor: '#FFE400!important',
          opacity: .3,
        },
      },
      contained: {
        boxShadow: `8px 8px #000000`,
        backgroundColor: '#FFE400',
        borderRadius: 0,
       "&:hover": {
         backgroundColor: '#ffffff',
         border: `1px solid #000000`,
         boxShadow: 'none'
       },
      },
      outlined: {
        borderRadius: 0,
        border: `1px solid #000000`,
       "&:hover": {
         backgroundColor: '#000000',
         color: '#ffffff',
       },
      },
      primary: {
        boxShadow: `none`,
        backgroundColor: 'red',
        borderRadius: 0,
        color: `#000000`,
       "&:hover": {
         backgroundColor: '#000000',
         color: `#ffffff`,
         border: `1px solid #ffffff`,
         boxShadow: 'none'
       },
      },
      containedSizeLarge: {
      padding: '16px 32px',
      fontSize: '16px'
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "none",
      },
    },
    MuiChip: {
      root: {
        borderRadius: `none`,
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gilroyEB, gilroyL, spaceGroteskL, spaceGroteskR, spaceGroteskM, spaceGroteskB],
        html: {
          fontSize: '14px',
          overflowX: 'hidden',
        },
        body: {
          backgroundColor: '#ffffff',
          scrollBehavior: 'smooth',
        },
        '@keyframes defilement': {
          '0%':{
            transform: 'translate(400%)',
          },
          '100%': {
            transform: 'translate(-200%)',
          }
        },
        '@keyframes spin': {
          '0%': { 
            transform: 'rotate(0deg)',
          },
          '100%': { 
            transform: 'rotate(360deg)',
          },
        },
      },
    },
  },
});

  export default theme;