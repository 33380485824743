exports.components = {
  "component---src-components-templates-product-js": () => import("./../../../src/components/templates/Product.js" /* webpackChunkName: "component---src-components-templates-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informations-js": () => import("./../../../src/pages/informations.js" /* webpackChunkName: "component---src-pages-informations-js" */),
  "component---src-pages-informations-mentions-js": () => import("./../../../src/pages/informations/mentions.js" /* webpackChunkName: "component---src-pages-informations-mentions-js" */),
  "component---src-pages-informations-personal-data-management-js": () => import("./../../../src/pages/informations/personal-data-management.js" /* webpackChunkName: "component---src-pages-informations-personal-data-management-js" */),
  "component---src-pages-informations-terms-and-conditions-of-sale-js": () => import("./../../../src/pages/informations/terms-and-conditions-of-sale.js" /* webpackChunkName: "component---src-pages-informations-terms-and-conditions-of-sale-js" */),
  "component---src-pages-informations-transport-js": () => import("./../../../src/pages/informations/transport.js" /* webpackChunkName: "component---src-pages-informations-transport-js" */),
  "component---src-pages-product-gift-card-js": () => import("./../../../src/pages/product/gift-card.js" /* webpackChunkName: "component---src-pages-product-gift-card-js" */),
  "component---src-pages-return-confirm-js": () => import("./../../../src/pages/return-confirm.js" /* webpackChunkName: "component---src-pages-return-confirm-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

